<template>
	<article>
		<hero-image
			:alt="hero.alt"
			:heading="hero.heading"
			:img-small="hero.imgSmall"
			:img-large="hero.imgLarge"
		/>
		<v-container class="py-0">
			<v-row no-gutters align="center">
				<v-col cols="12">
					<breadcrumbs :crumbs="crumbs" />
				</v-col>
			</v-row>
		</v-container>
		<v-container class="content" tag="section">
			<v-row>
				<v-col cols="12">
					<v-alert
						v-if="!survey"
						outlined
						prominent
						type="success"
					>
						Thank you for contacting Sydney Kids Neuropsychology. Someone will be in touch soon.
					</v-alert>
					<v-alert
						v-else
						:color="$vuetify.theme.themes.light.info"
						icon="$vuetify.icons.exclamation"
						outlined
						prominent
						type="info"
					>
						<v-row align="center">
							<v-col cols="12" sm="9" lg="10">
								<span class="cta__copy">Thank you for filling out our survey.</span>
							</v-col>
							<v-col cols="12" sm="3" lg="2">
								<v-btn
									class="cta__btn"
									block
									large
									rounded
									depressed
									color="primary"
									to="/"
								>
									Homepage
								</v-btn>
							</v-col>
						</v-row>
					</v-alert>
				</v-col>
				<v-col v-if="!survey" cols="12">
					<h3>A short survey</h3>
					<p>While we have your attention could you please tell us how you found Sydney Kids Neuropsychology?</p>
					<v-form
						class="contact-form__form"
						action="/success?survey=true"
						data-netlify="true"
						data-netlify-honeypot="bot-field"
						name="SydKidsPsychSurvey"
						method="post"
					>
						<v-row>
							<v-col cols="12" sm="6" lg="4">
								<input type="hidden" name="form-name" value="SydKidsPsychSurvey">
								<input name="bot-field" class="d-none">
								<v-select
									v-model="form.survey"
									:items="select"
									label="How did you find us"
									name="survey"
									outlined
									prepend-icon="$vuetify.icons.faqs"
									rounded
									:success="valid"
									:rules="[rules.required, rules.notEmpty]"
									class="contact-form__input"
									@input="surveyCheck"
								/>
								<input :value="form.survey" name="response" type="hidden">
							</v-col>
							<v-col cols="12" sm="6" lg="8">
								<v-btn
									color="primary"
									x-large
									type="submit"
									depressed
									:disabled="!valid"
									rounded
									class="contact-form__submit"
								>
									Submit
								</v-btn>
							</v-col>
						</v-row>
					</v-form>
				</v-col>
			</v-row>
		</v-container>
	</article>
</template>

<script>
import { _MxAppViewClass } from '@/mixins/global';
import Breadcrumbs from '@/components/breadcrumbs/Breadcrumbs';
import HeroImage from '@/components/image/HeroImage';
export default {
	name: 'Success',
	components: {
		Breadcrumbs,
		HeroImage
	},
	metaInfo: {
		title: 'Success',
		meta: [
			{
				name: 'description',
				content: 'Sydney Kids Neuropsychology.'
			},
			{
				name: 'robots',
				content: 'noindex'
			}
		]
	},
	mixins: [_MxAppViewClass],
	data() {
		return {
			crumbs: [
				{
					text: 'home',
					disabled: false,
					to: '/'
				},
				{
					text: this.$route.name,
					disabled: true
				}
			],
			form: {
				survey: null
			},
			hero: {
				alt: this.$route.name,
				heading: this.$route.name,
				imgSmall: 'hero-04-small',
				imgLarge: 'hero-04-large'
			},
			rules: {
				notEmpty: (value) =>
					(!!this.form.survey && !!this.form.survey.length) ||
					'required',
				required: (value) => !!value || 'required'
			},
			select: [
				'Internet Search',
				'Professional Referral',
				'Social Media',
				'Word of Mouth',
				'Other'
			]
		};
	},
	computed: {
		survey() {
			const params = new URLSearchParams(
				window.location.search.substring(1)
			);
			const survey = params.get('survey');
			if (survey === 'true') {
				return true;
			} else {
				return false;
			}
		},
		valid() {
			return !!this.form.survey;
		}
	},
	methods: {
		surveyCheck() {
			if (this.form.survey && !this.form.survey.length) {
				this.form.survey = null;
			}
		}
	}
};
</script>
